<template>
  <b-row>

    <h2 class="pl-1">{{ $t('side_bar.specification') }}</h2>

    <!--  BEFORE TABLE  -->
    <div class="d-flex justify-content-between col-12">
      <b-col
        md="4"
        class="my-1 px-0"
      >
        <b-form-group
          class="mb-0"
        >
          <b-input-group size="md">
            <b-form-input
              id="filterInput"
              v-model="searchFilter"
              type="search"
              :placeholder="$t('reusable.search_placeholder')"
            />
            <b-input-group-append>
              <b-button
                :disabled="!searchFilter"
                @click="searchFilter = ''"
              >
                {{ $t('reusable.clear_btn') }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <div
        class="my-1 float-right"
      >
        <modal-button
          ripple="rgba(113, 102, 240, 0.15)"
          size="xl"
          name="modal-main"
          :modalButton="$t('reusable.modal_add_btn')"
          @btn-emit="createNewSpec"
          @open-emit="resetInputs"
          :modal-title="$t('reusable.modal_add_btn')"
          variant="success"
        >
          <template v-slot:button @click="createNewSpec">{{ $t('reusable.modal_add_btn') }}</template>

          <template v-slot:modal-body>
            <div class="d-flex flex-wrap">

              <b-card
                :title="$t('spec_table.add_spec')"
                class="mb-4 card_box-shadow col-12"
              >

                <div class="d-flex flex-wrap">
                  <b-col cols="6">
                    <b-form-group
                      :label="$t('spec_table.add_spec_label_ru')"
                      label-for="defaultLabel"
                    >
                      <b-form-input
                        v-model="name.ru"
                        size="lg"
                        :placeholder="$t('spec_table.add_spec_label_ru')"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group
                      :label="$t('spec_table.add_spec_label_uz')"
                      label-for="defaultLabel"
                    >
                      <b-form-input
                        v-model="name.uz"
                        size="lg"
                        :placeholder="$t('spec_table.add_spec_label_uz')"
                      />
                    </b-form-group>
                  </b-col>
                </div>

              </b-card>

              <b-card
                :title="$t('spec_table.add_option')"
                class="mb-4 card_box-shadow col-12"
              >

                <div class="d-flex flex-wrap align-items-center" v-for="(option,index) in options" :key="index">
                  <div class="d-flex flex-wrap col-11 px-0">
                    <b-col cols="6">
                      <b-form-group
                        :label="$t('spec_table.add_option_label_ru')"
                        label-for="defaultLabel"
                      >
                        <b-form-input
                          v-model="option.name.ru"
                          size="lg"
                          :placeholder="$t('spec_table.add_option_label_ru')"
                        />
                      </b-form-group>

                    </b-col>

                    <b-col cols="6">
                      <b-form-group
                        :label="$t('spec_table.add_option_label_uz')"
                        label-for="defaultLabel"
                      >
                        <b-form-input
                          v-model="option.name.uz"
                          size="lg"
                          :placeholder="$t('spec_table.add_option_label_uz')"
                        />
                      </b-form-group>
                    </b-col>
                  </div>

                  <div class="col-1">
                    <b-button
                      @click="removeInputField(index)"
                      class="btn-danger py-0 px-1"
                      style="font-size: 30px;"
                    >
                      -
                    </b-button>
                  </div>

                </div>

                <div class="px-1">
                  <b-button
                    class="btn-success py-0"
                    style="font-size: 30px;"
                    @click="addInputField"
                  >
                    +
                  </b-button>
                </div>

              </b-card>


            </div>
          </template>
        </modal-button>

      </div>
    </div>

    <!--  TABLE  -->
    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        sort-icon-left
        :busy="isBusy"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="searchFilter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-1">{{ $t('reusable.isBusy') }}</strong>
          </div>
        </template>

        <template v-slot:head(is_deleted)="data">
          <b-form-select
            size="md"
            v-model="status"
            class="w-100"
          >
            <template #first>
              <option
                selected
                :value="null"
              >
                Выберите статус
              </option>
            </template>
            <option
              v-for="(status, index) in statuses"
              :key="index"
              :value="status"
            >
              {{ status }}
            </option>
          </b-form-select>
        </template>

        <template #cell(is_deleted)="data">
          <b-badge :variant="changeVariant(data.item)">
            {{ data.item.is_deleted }}
          </b-badge>
        </template>

        <template #cell(name)="data">
          {{ checkLocales(data.item.name) }}
        </template>

        <template #cell(crud_row)="data">
          <div class="d-flex float-right">

            <b-dropdown
              no-caret
              id="table-action-dropdown"
              variant="link"
              size="md"
            >
              <template
                #button-content
                class="p-0"
              >
                <feather-icon
                  icon="MoreVerticalIcon"
                  class="p-0"
                />
              </template>

              <!--   PREVIEW BUTTON   -->
              <b-dropdown-item
                class="px-0"
              >
                <modal-button
                  ripple="rgba(113, 102, 240, 0.15)"
                  size="xl"
                  :hide-footer="true"
                  :name="`modal-preview-${data.item.id}`"
                  :backdrop-close="true"
                  :modalButton="$t('region_table.country_edit_modal_title')"
                  :id="data.item.id"
                  :modal-title="$t('reusable.preview_btn')"
                  variant="flat-primary"
                  @open-emit="getSpecById(data.item.id)"
                >
                  <template
                    v-slot:button
                    style="width: 100% !important;"
                  >
                    <div class="d-flex align-items-center">
                      <feather-icon
                        icon="EyeIcon"
                        size="21"
                        style="margin-right: 6px"
                      />
                      <span>{{ $t('reusable.preview_btn') }}</span>
                    </div>
                  </template>

                  <template v-slot:modal-body>

                    <div class="d-flex flex-wrap">
                      <b-card
                        :title="$t('spec_table.name')"
                        class="mb-4 card_box-shadow col-12"
                      >

                        <div class="d-flex flex-wrap">

                          <b-col cols="6">
                            <b-form-group
                              :label="$t('spec_table.add_spec_label_ru')"
                              label-for="defaultLabel"
                            >
                              <b-form-input
                                v-model="name.ru"
                                size="lg"
                                :disabled="!editAllow"
                                :placeholder="$t('spec_table.add_spec_label_ru')"
                              />
                            </b-form-group>
                          </b-col>

                          <b-col cols="6">
                            <b-form-group
                              :label="$t('spec_table.add_spec_label_uz')"
                              label-for="defaultLabel"
                            >
                              <b-form-input
                                v-model="name.uz"
                                size="lg"
                                :disabled="!editAllow"
                                :placeholder="$t('spec_table.add_spec_label_uz')"
                              />
                            </b-form-group>
                          </b-col>

                          <b-col cols="6">
                            <b-form-group
                              :label="$t('region_table.created_at')"
                              label-for="defaultLabel"
                            >
                              <b-form-input
                                v-model="createdAt"
                                size="lg"
                                :disabled="!editAllow"
                                :placeholder="$t('region_table.created_at')"
                              />
                            </b-form-group>
                          </b-col>

                          <b-col cols="6">
                            <b-form-group
                              :label="$t('region_table.updated_at')"
                              label-for="defaultLabel"
                            >
                              <b-form-input
                                v-model="updatedAt"
                                size="lg"
                                :disabled="!editAllow"
                                :placeholder="$t('region_table.updated_at')"
                              />
                            </b-form-group>
                          </b-col>

                        </div>

                      </b-card>

                      <b-card
                        :title="$t('spec_table.option_name')"
                        class="card_box-shadow col-12"
                      >

                        <div class="d-flex flex-wrap align-items-center" v-for="(option,index) in options" :key="index">
                          <div class="d-flex flex-wrap col-12 px-0">
                            <b-col cols="6">
                              <b-form-group
                                :label="$t('spec_table.add_option_label_ru')"
                                label-for="defaultLabel"
                              >
                                <b-form-input
                                  v-model="option.name.ru"
                                  size="lg"
                                  :disabled="!editAllow"
                                  :placeholder="$t('spec_table.add_option_label_ru')"
                                />
                              </b-form-group>

                            </b-col>

                            <b-col cols="6">
                              <b-form-group
                                :label="$t('spec_table.add_option_label_uz')"
                                label-for="defaultLabel"
                              >
                                <b-form-input
                                  v-model="option.name.uz"
                                  size="lg"
                                  :disabled="!editAllow"
                                  :placeholder="$t('spec_table.add_option_label_uz')"
                                />
                              </b-form-group>
                            </b-col>
                          </div>

                        </div>

                      </b-card>

                    </div>
                  </template>


                </modal-button>
              </b-dropdown-item>

              <!--  EDIT BUTTON  -->
              <b-dropdown-item
                class="px-0"
              >
                <modal-button
                  ripple="rgba(113, 102, 240, 0.15)"
                  size="xl"
                  :backdrop-close="true"
                  :name="`modal-edit-${data.item.id}`"
                  :modalButton="$t('reusable.save_btn')"
                  :id="data.item.id"
                  :modal-title="$t('reusable.modal_edit_btn')"
                  variant="flat-warning"
                  @open-emit="getSpecById(data.item.id)"
                  @btn-emit="updateSpec(data.item.id, data.item)"
                >
                  <template
                    v-slot:button
                    style="width: 100% !important;"
                  >
                    <div class="d-flex align-items-center">
                      <feather-icon
                        icon="Edit2Icon"
                        size="21"
                        style="margin-right: 6px"
                      />
                      <span>{{ $t('reusable.modal_edit_btn') }}</span>
                    </div>
                  </template>

                  <template v-slot:modal-body>

                    <div class="d-flex flex-wrap">
                      <b-card
                        :title="$t('spec_table.add_spec')"
                        class="mb-4 card_box-shadow col-12"
                      >

                        <div class="d-flex flex-wrap">
                          <b-col cols="6">
                            <b-form-group
                              :label="$t('spec_table.add_spec_label_ru')"
                              label-for="defaultLabel"
                            >
                              <b-form-input
                                v-model="name.ru"
                                size="lg"
                                :placeholder="$t('spec_table.add_spec_label_ru')"
                              />
                            </b-form-group>
                          </b-col>

                          <b-col cols="6">
                            <b-form-group
                              :label="$t('spec_table.add_spec_label_uz')"
                              label-for="defaultLabel"
                            >
                              <b-form-input
                                v-model="name.uz"
                                size="lg"
                                :placeholder="$t('spec_table.add_spec_label_uz')"
                              />
                            </b-form-group>
                          </b-col>
                        </div>

                      </b-card>

                      <b-card
                        :title="$t('spec_table.add_option')"
                        class="mb-4 card_box-shadow col-12"
                      >

                        <div class="d-flex flex-wrap align-items-center" v-for="(option,index) in options" :key="index">
                          <div class="d-flex flex-wrap col-11 px-0">
                            <b-col cols="6">
                              <b-form-group
                                :label="$t('spec_table.add_option_label_ru')"
                                label-for="defaultLabel"
                              >
                                <b-form-input
                                  v-model="option.name.ru"
                                  size="lg"
                                  :placeholder="$t('spec_table.add_option_label_ru')"
                                />
                              </b-form-group>

                            </b-col>

                            <b-col cols="6">
                              <b-form-group
                                :label="$t('spec_table.add_option_label_uz')"
                                label-for="defaultLabel"
                              >
                                <b-form-input
                                  v-model="option.name.uz"
                                  size="lg"
                                  :placeholder="$t('spec_table.add_option_label_uz')"
                                />
                              </b-form-group>
                            </b-col>
                          </div>

                          <div class="col-1">
                            <b-button
                              @click="removeInputField(index)"
                              class="btn-danger py-0 px-1"
                              style="font-size: 30px;"
                            >
                              -
                            </b-button>
                          </div>

                        </div>

                        <div class="px-1">
                          <b-button
                            class="btn-success py-0"
                            style="font-size: 30px;"
                            @click="addInputField"
                          >
                            +
                          </b-button>
                        </div>
                      </b-card>
                    </div>
                  </template>

                </modal-button>
              </b-dropdown-item>

              <!--  DELETE BUTTON  -->
              <b-dropdown-item>
                <modal-button
                  ripple="rgba(113, 102, 240, 0.15)"
                  size="sm"
                  :name="`modal-delete-${data.item.id}`"
                  :modalButton="$t('region_table.country_delete_modal_title')"
                  :modal-title="$t('region_table.country_delete_modal_title')"
                  variant="flat-danger"
                  :id="data.item.id"
                  @btn-emit="deleteSpec"
                >
                  <template v-slot:button class="w-100">
                    <div class="d-flex align-items-center">
                      <feather-icon
                        icon="Trash2Icon"
                        size="21"
                        style="margin-right: 6px"
                      />
                      <span>{{ $t('reusable.modal_delete_btn') }}</span>
                    </div>
                  </template>

                  <template v-slot:modal-body>
                    <div class="my-2">
                      {{ $t('spec_table.delete_spec_text') }}
                    </div>
                  </template>

                </modal-button>
              </b-dropdown-item>

            </b-dropdown>

          </div>
        </template>

        <template #cell(options)="{ item }">
          <div v-for="option in getOptions(item.options)" :key="option">
            {{ option }}
          </div>
        </template>

      </b-table>
    </b-col>

    <!--  PAGINATION  -->
    <b-col
      cols="12"
      class="mb-4"
    >
      <b-pagination
        v-if="showPagination"
        v-model="pagination.current"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>

  </b-row>
</template>

<script>
import {
  BTable,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BFormCheckbox,
  BDropdownItem,
  BCard,
  BSpinner,
} from 'bootstrap-vue'
import ModalButton from '@/views/ui/modals/ModalButton.vue'
import api from '@/services/api'
import infinityScrollSelect from '@/views/ui/infinity-scroll/InfinityScrollSelect'

export default {
  name: 'Specification',
  components: {
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BCard,
    BSpinner,
    ModalButton,
    infinityScrollSelect
  },

  data() {
    return {
      createdAt: '',
      updatedAt: '',
      isBusy: false,
      editAllow: false,
      isDeleted: false,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      searchFilter: null,
      filter: [],
      filterOn: [],
      status: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      statuses: [true, false],
      fields: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'name',
          label: this.$t('spec_table.name'),
          sortable: true,
        },
        {
          key: 'options',
          label: this.$t('spec_table.option_name'),
        },
        {
          key: 'created_at',
          label: this.$t('region_table.created_at'),
        },
        {
          key: 'updated_at',
          label: this.$t('region_table.updated_at'),
        },
        {
          key: 'crud_row',
          label: ' ',
        },
      ],
      variant: '',
      items: [],
      name: {
        ru: '',
        uz: '',
      },
      options: [
        {
          name: {
            ru: '',
            uz: '',
          }
        },
      ],
      pagination: {
        current: 1,
        total: 5,
        per_page: 5
      },
    }
  },
  watch: {
    '$route.query': {
      handler(query) {
        // const routeQueryPage = parseInt(query.page)
        this.pagination.current = query.page
        this.fetchSpecList()
      },
      deep: true,
      immediate: true
    },

    'pagination.current': {
      handler(page) {
        this.replaceRouter({
          ...this.query,
          page,
        })
      },
    }
  },

  computed: {
    rows() {
      return this.items.length
    },
    query() {
      return Object.assign({}, this.$route.query)
    },

    hasItems() {
      return this.items.length > 0
    },

    showPagination() {
      return this.hasItems && !this.isBusy
    },

    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key,
        }))
    },
  },

  async created() {
    // await this.fetchCityList()
  },

  methods: {

    removeInputField(value) {
      this.options.splice(value, 1)
    },

    addInputField() {
      this.options = [...this.options, {
        name: {
          ru: '',
          uz: '',
        }
      }]
    },

    resetInputs() {
      this.name = {
        uz: null,
        ru: null
      }
      this.options = [{
        name: {
          ru: '',
          uz: '',
        }
      }]
    },

    replaceRouter(query) {
      this.$router.replace({ query })
        .catch(() => {
        })
    },

    async fetchSpecList() {
      const data = {
        with: {
          0: 'options'
        },
        page: this.$route.query.page
      }
      this.isBusy = true
      await api.products.fetchSpec(data)
        .then(res => {
          this.items = res.data.data
          this.pagination.per_page = res.data.meta.per_page
          this.pagination.total = res.data.meta.total
          this.pagination.current = res.data.meta.current_page
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.isBusy = false
        })
    },

    async getSpecById(id) {
      const data = {
        with: {
          0: 'options'
        }
      }
      this.editAllow = false
      await api.products.fetchOneSpec(id, data)
        .then(res => {
          console.log(res)
          this.createdAt = res.data.created_at
          this.updatedAt = res.data.updated_at
          this.options = res.data.options
          this.name.ru = res.data.name.ru
          this.name.uz = res.data.name.uz
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {

        })
    },

    createNewSpec() {
      const data = {
        name: this.name,
        options: this.options
      }
      api.products.createSpec(data)
        .then(res => {
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.fetchSpecList()
          this.name = ''
        })
    },

    deleteSpec(id) {
      api.products.deleteSpec(id)
        .then(() => {
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.fetchSpecList()
        })
    },

    updateSpec(id, data) {
      const body = {
        name: this.name,
        options: this.options
      }

      api.products.updateSpec(id, body)
        .then(res => {
          this.options = res.data.options
          this.name.ru = res.data.name.ru
          this.name.uz = res.data.name.uz
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.fetchSpecList()
        })
    },

    changeVariant(value) {
      if (value.is_deleted) return 'danger'
      return 'success'
    },

    checkLocales(value) {
      if (localStorage.lang === 'ru') {
        return value.ru
      }
      return value.uz
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },

    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of modals/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    getOptions(options) {
      let optionNames = []
      if (!options.length) return ''
      options.forEach((option) => {
        optionNames.push(option.name[localStorage.lang])
      })
      return optionNames
    },

  },
}
</script>

<style lang="scss">

[dir] .dropdown-item {
  padding: 0;
}

[dir=ltr] .custom-checkbox.b-custom-control-lg .custom-control-label::before {
  left: 0;
  top: 0;
  //position: static;
  margin-right: 12px;
}

[dir=ltr] .custom-checkbox.b-custom-control-lg .custom-control-label::after {
  left: 0;
  top: 0;
  margin-right: 12px;
}

.custom-checkbox .custom-control-label, .custom-radio .custom-control-label {
  display: flex;
  align-items: center;
}

::v-deep .vs__dropdown-menu {
  max-height: 100px !important;
}

.list-group-wrapper {
  position: relative;
}

.list-group {
  overflow: auto;
  height: 100px;
  border: 2px solid #dce4ec;
  border-radius: 5px;
}

.list-group-item {
  margin-top: 1px;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 2px solid #dce4ec;
}


.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}

.fade-enter, .fade-leave-to {
  opacity: 0
}

h4.card-title {
  padding: 0 14px;
}

.card_box-shadow {
  box-shadow: 0 0 10px #1d172e !important;
}

</style>
